import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi',
    },
    theme: {
        themes: {
            light: {
                primary: colors.grey.lighten3,
                secondary: colors.grey.darken3,
                accent: '#FAD20C',
                error: colors.red.lighten2
            },
        },
    },
});
