<template>
    <v-app v-resize="handleResize">
        <router-view/>
    </v-app>
</template>

<script>
    export default {
        name: 'App',
        methods: {
            handleResize() {
                this.$store.dispatch('setHeight', window.innerHeight);
                this.$store.dispatch('setWidth', window.innerWidth)
            }
        }
    }
</script>

<style>
    .content-layout {
        padding: 20px 1.5% 20px 1.5%;
    }

    /* width */
    ::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 3px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: grey;
        border-radius: 3px;
    }
</style>
