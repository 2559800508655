import Vue from 'vue'
import Vuex from 'vuex'

import is from 'is_js'


Vue.use(Vuex)

class IdentifyRequestStatus {
    constructor() {
        this.denied = 1;
        this.waitingForApprove = 2;
        this.approved = 3;

        this.titles = {
            [this.denied]: 'Нет',
            [this.waitingForApprove]: 'Активный запрос',
            [this.approved]: 'Да'
        }

        this.selectItems = [
            {title: this.titles[this.denied], idRequestStatus: this.denied},
            {title: this.titles[this.waitingForApprove], idRequestStatus: this.waitingForApprove},
            {title: this.titles[this.approved], idRequestStatus: this.approved}
        ]
    }
}

class TimePeriods {
    constructor() {
        this.month = 1;
        this.quarter = 2;

        this.default = this.month;

        this.items = [
            {value: this.month, title: "Месяц"},
            {value: this.quarter, title: "Квартал"},
        ]

    }
}

export default new Vuex.Store({
    state: {
        baseUrl: "https://api.admin.reeqoo.com/withoutPayment/api/v1/admin",
        socketUrl: "https://socket.admin.reeqoo.com/admin",
        isMobile: is.mobile() || window.innerWidth <= 800,
        height: window.innerHeight,
        width: window.innerWidth,
        drawer: false,
        adminData: {
            login: '',
            name: '',
            isRoot: false
        },
        currentCar: {
            brand: JSON.parse(localStorage.getItem('brand') || '{}'),
            model: JSON.parse(localStorage.getItem('model') || '{}'),
            generation: JSON.parse(localStorage.getItem('generation') || '{}'),
            serie: JSON.parse(localStorage.getItem('serie') || '{}'),
            trim: JSON.parse(localStorage.getItem('trim') || '{}')
        },
        addItem: {},
        identifyRequestStatus: new IdentifyRequestStatus(),
        sellerCountryItems: [],
        customerCountryItems: [],
        customerOrderStatus: {
            new: 0,
            activate: 1,
            deactivate: 2,
            removed: 3,
            hiddenByAdmin: 4,
        },
        carTypesItems: [
            {
                idCarType: 1,
                title: "Автомобиль"
            }
        ],
        chartLabel: "По марке",
        timePeriods: new TimePeriods()
    },
    mutations: {
        toggleDrawer(state) {
            state.drawer = !state.drawer
        },
        setWidth(state, newWidth) {
            state.width = newWidth;
            state.isMobile = is.mobile() || newWidth <= 800
        },
        setHeight(state, newHeight) {
            state.height = newHeight
        },
        setAdminData(state, newData) {
            state.adminData.login = newData.login;
            state.adminData.name = newData.name;
            state.adminData.isRoot = newData.isRoot
        },
        setMaxDomainsCount(state, newData) {
            state.maxDomainsCount = newData
        },
        setIsVisibleAccount(state, newdata) {
            state.isVisibleAccount = newdata
        },
        setCurrentCar(state, newData) {
            state.currentCar[newData.key] = newData.data;
            localStorage.setItem(newData.key, JSON.stringify(newData.data))
        },
        setAddItem(state, newData) {
            state.addItem = newData
        },
        setCustomerCountryItems(state, newData) {
            state.customerCountryItems = newData
        },
        setSellerCountryItems(state, newData) {
            state.sellerCountryItems = newData
        },
        setChartLabel(state, newData) {
            state.chartLabel = newData
        }
    },
    actions: {
        toggleDrawer({commit}) {
            commit('toggleDrawer')
        },
        setWidth({commit}, newWidth) {
            commit('setWidth', newWidth)
        },
        setHeight({commit}, newHeight) {
            commit('setHeight', newHeight)
        },
        setAdminData({commit}, newData) {
            commit('setAdminData', newData)
        },
        setMaxDomainsCount({commit}, newData) {
            commit('setMaxDomainsCount', newData)
        },
        setIsVisibleAccount({commit}, newData) {
            commit('setIsVisibleAccount', newData)
        },
        setCurrentCar({commit}, newData) {
            console.log('setCurCar', newData)
            commit('setCurrentCar', newData)
        },
        setAddItem({commit}, data) {
            commit('setAddItem', data)
        },
        setCustomerCountryItems({commit}, data) {
            commit('setCustomerCountryItems', data)
        },
        setSellerCountryItems({commit}, data) {
            commit('setSellerCountryItems', data)
        },
        setChartLabel({commit}, data) {
            commit('setChartLabel', data)
        }
    }
})
