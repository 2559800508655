import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import vuetify from './plugins/vuetify';
import axios from 'axios'
import 'vuetify/dist/vuetify.min.css'
import moment from 'moment'
import VueRx from 'vue-rx'
import VueSocketIOExt from 'vue-socket.io-extended';
import io from 'socket.io-client';

Vue.use(VueSocketIOExt, io(store.state.socketUrl));

Vue.use(VueRx);
require('moment/locale/ru');

Vue.config.productionTip = false;

Vue.prototype.$http = axios.create({
    baseURL: store.state.baseUrl,
    mode: 'no-cors',
    headers: {'token': localStorage.getItem("token")}
});
// Vue.prototype.$http.defaults.token = localStorage.getItem('token')

Vue.filter('localTime', function (date) {
    if (date) {
        return moment.utc(date || new Date()).local().format("DD MMM YYYY, HH:mm")
    }
});

Vue.filter('localDate', function (date) {
    if (date) {
        return moment.utc(date || new Date()).local().format("D MMMM")
    }
});

Vue.filter('createDate', function (date) {
    if (date) {
        return moment.utc(date || new Date()).local().format("DD.MM.YYYY")
    }
});


Vue.filter('truncated', (str, length) => str.substring(0, length) + (str.length > length ? '...' : ''));

new Vue({
    router,
    store,
    vuetify,
    render: function (h) {
        return h(App)
    }
}).$mount('#app')
