import Vue from 'vue'
import Router from 'vue-router'

const axios = require('axios');
import store from './store'

Vue.use(Router);

const indexEnterGuard = function (from, to, next) {
    const token = localStorage.getItem('token');
    if (token) {
        const params = {
            headers: {token}
        };
        axios.get(`${store.state.baseUrl}/profile`, params)
            .then(response => {
                store.dispatch('setAdminData', response.data.data);
                next()
            }).catch(error => {
            next({name: 'login'})
        })
    } else next({name: 'login'})
};

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'home',
            beforeEnter: indexEnterGuard,
            component: () => import("./views/HomeView"),
            redirect: {name: 'requests'},
            children: [
                {
                    path: '/customers',
                    name: 'users',
                    component: () => import("./views/CustomersView")
                },
                {
                    path: '/sellers',
                    name: 'sellers',
                    component: () => import("./views/SellersView")
                },
                {
                    path: '/requests',
                    name: 'requests',
                    component: () => import("./views/BuyRequestsView"),
                    props: {
                        idCountry: {
                            type: Number,
                            default: null
                        },
                        date: {
                            type: String,
                            default: null
                        }
                    },
                },
                {
                    path: '/statistics',
                    name: 'statistics',
                    component: () => import("./views/StatisticsView"),
                    redirect: {name: 'statisticsByCar'},
                    children: [
                        {
                            path: 'cars',
                            name: 'statisticsByCar',
                            component: () => import("./components/statistics/StatisticsByCar"),
                        },
                        {
                            path: 'orders',
                            name: 'statisticsByCountry',
                            component: () => import("./components/statistics/StatisticsByCountry"),
                        },
                        {
                            path: 'offers',
                            name: 'statisticsByOffers',
                            component: () => import("./components/statistics/StatisticsByOffers"),
                        }
                    ]
                },
                {
                    path: '/settings',
                    name: 'settings',
                    component: () => import("./views/SettingsView")
                },
                {
                    path: '/cars',
                    name: 'cars',
                    component: () => import("./views/CarsView")
                },
                {
                    path: '/profile',
                    name: 'profile',
                    component: () => import("./views/ProfileView")
                },
            ]
        },
        {
            path: '/login',
            name: 'login',
            component: () => import("./views/LoginView")
        },
        {
            path: '/recovery',
            name: 'recovery',
            component: () => import("./views/RecoveryView")
        },
        {
            path: '/registration',
            name: 'registration',
            component: () => import("./views/RegistrationView")
        },
        {
            path: '/confirmation',
            name: 'confirmation',
            component: () => import("./views/ConfirmationView")
        },
        {
            path: '*',
            name: '404',
            component: () => import("./views/NotFoundView")
        }
    ]
})
